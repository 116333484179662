<template>
    <v-sheet
        class="global-page-banner font-weight-medium py-1"
        :style="{ height: isMobile ? '50px' : '30px' }"
        :color="color"
        width="100%"
    >
        <v-container
            class="py-0 align-center justify-center on-surface-odd font-weight-medium font-size-8 position-relative h-100 w-100"
        >
            <slot />
        </v-container>
    </v-sheet>
</template>

<script lang="ts" setup>
    import type { GlobalPageBannerType } from '~/types';

    const props = defineProps<{
        isMobile: boolean;
        type: GlobalPageBannerType;
    }>();

    const emit = defineEmits([
        'on-hide'
    ]);

    const color = computed<string>(() => {
        switch (props.type) {
            case 'mbr-credit-hold-alert':
                return 'error';
            case 'shipping-info':
            default:
                return 'surface-odd';
        }
    });
</script>

<style lang="scss">
.global-page-banner {
    &-close {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
    }
}
</style>
